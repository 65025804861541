import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import InputControl from "../../../inputController"
import { auth } from "../../../firebase";
import "./style.css";

const Login = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState({
    email: "",
    password: "",
  });

  // login through email and password
  const [errormsg, setErrormsg] = useState("");

  const handlesubmission = () => {
    if (!value.email || !value.password) {
      setErrormsg("Fill in all fields");
    } else {
      signInWithEmailAndPassword(auth, value.email, value.password)
        .then((res) => {
          // console.log(res);

          setValue({
            email: "",
            password: "",
          });

          navigate("/");
        })
        .catch((error) => {
          // console.log('Error creating user:', error.message);
          setErrormsg(error.message);

          if (error.code === "auth/user-not-found") {
            // User does not exist, navigate to signup page
            setErrormsg(error.message);
            // navigate('/signup');
          }
        });
    }
  };

  // login through google
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // console.log(result);
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const signWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        if (error.code === "auth/account-exists-with-different-credential") {
          // User tried to sign in with Facebook, but an account with the same email exists
          // using a different authentication provider.
          setErrormsg(
            "An account with this email already exists. Sign in using the same provider you used during sign-up."
          );
        } else {
          console.log(error);
        }
      });
  };
  

  return (
    <div className="login_container ">
      <div className="login_innerBox">
        <div className="back">
          <button
            className="btn back_btn"
            onClick={() => {
              navigate("/");
            }}
          >
            <i className="fa fa-angle-double-left"></i> Back
          </button>
        </div>
        <h1 className="login_heading">Login</h1>
        <InputControl
          type="email"
          label="Email:"
          placeholder="Enter email"
          onChange={(e) =>
            setValue((prev) => ({ ...prev, email: e.target.value }))
          }
          value={value.email}
        />
        <InputControl
          type="password"
          label="Password:"
          placeholder="Enter Password"
          onChange={(e) =>
            setValue((prev) => ({ ...prev, password: e.target.value }))
          }
          value={value.password}
        />
        <p>
          <Link to="/forgot_password">Forgot Password?</Link>
        </p>

        <div className="login_method">
          <button
            className="btn btn-block btn-social btn-facebook"
            id="swgoogle"
            onClick={signWithFacebook}
          >
            <i className="fab fa-facebook"></i>{" "}
            <span className="iconss">Sign In with Facebook</span>
          </button>
          <button
            className="btn btn-block btn-social btn-google"
            onClick={signInWithGoogle}
            id="swgoogle"
          >
            <i className="fab fa-google"></i>{" "}
            <span className="iconss">Sign In with Google</span>
          </button>
        </div>

        <div className="login_footer">
          <b className="login_error">{errormsg}</b>
          <button onClick={handlesubmission}>Login</button>
          <p className="login_p">
            Create an account?{" "}
            <span>
              <Link to="/signup">Sign up</Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
