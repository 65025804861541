// //Pdf-Chatbot
import './App.css';
import Main from './Main';
import { Helmet } from 'react-helmet';
import Sidebar from './sidebar/Sidebar'
import Form from './Form/Form';
import {Route,BrowserRouter as Router, Routes} from 'react-router-dom' 
import Login from './pages/auth/login';
import Signup from './pages/auth/signUp';
import ResetPasswordPage from './pages/auth/forgotPassword'

function App() {

  return (
    <Router>
   
    <div style={{display:"flex"}}>
      <Sidebar/>
      
 
    </div>

    <Routes>

<Route path="forgot_password" element={<ResetPasswordPage />} />
<Route path="/login" element={<Login />} />
<Route path="/signup" element={<Signup />} />
<Route path='/' element={<Form/>}/>
</Routes>

</Router>
  );
}

export default App;
