import './Sidebar.css'
import {  IoSettingsOutline, IoLogInOutline, IoLogOutOutline } from "react-icons/io5";
import { FaRobot } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import {  FaRegUser  } from "react-icons/fa";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'
import { BiLogInCircle , BiLogOutCircle } from "react-icons/bi";
import { LiaRobotSolid } from "react-icons/lia";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, NavLink } from 'react-router-dom';
const Sidebar = () => {

    const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const handleSignOut = (e) => {
    e.preventDefault();

    auth
      .signOut()
      .then(() => {
        window.location.reload();
        navigate("/");
        setUser(null);
      })
      .catch((error) => {
        console.log("Sign out error:", error);
      });
  };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });

    return () => unsubscribe();
  }, []);

    return ( 
        <div className='ws'>
     
       
       <div className='top'>
       <div className='strip'>
        <p className='head'>🛠️&nbsp;Admin's<span className='dot'>-</span>Corner</p> 
        </div>
        {/* <div className='strip'>
        <p className='head2'>🛡️Admin<span className='dot'>&nbsp;~&nbsp;</span>Corner</p> 
        </div> */}
      <div className='options'>
        <div className='sideoption'>  <NavLink to='/' > <AiOutlineHome  color='white'  className='icons'/><span className='oname'>&nbsp;&nbsp;&nbsp;&nbsp;Home Page</span></NavLink> </div>
       <div className='sideoption'> <a href='https://free.kreatebots.com/' target="_blank"><LiaRobotSolid color='white' className='icons'/><span className='oname'>&nbsp;&nbsp;&nbsp;&nbsp;Try for free</span></a> </div>
      <div className='sideoption'>  <NavLink to='/my-account' target="_blank"> <FaRegUser  color='white'  className='icons'/><span className='oname'>&nbsp;&nbsp;&nbsp;&nbsp;My Account</span></NavLink> </div>
       <div className='sideoption'> <div className='signin'>
        {user ? (
                  <div onClick={handleSignOut}><BiLogOutCircle color='white' className='icons'/><span className='oname'>&nbsp;&nbsp;&nbsp;&nbsp;Sign out</span></div>
                  ) : (
                    <Link to='/login'><BiLogInCircle color='white' className='icons'/><span className='oname'>&nbsp;&nbsp;&nbsp;&nbsp;Sign in</span></Link>
                  )}
        </div>
        </div>
        <div className='sideoption'> <NavLink to='/contact'><RiContactsBookLine color='white' className='icons'/><span className='oname'>&nbsp;&nbsp;&nbsp;&nbsp;Contact us</span></NavLink> </div>

        </div>
        </div>

        <img src="https://storage.googleapis.com/json_articles/Kreatebots%20logo-01.png" className="hero"></img>

        

        </div>

     );
}
 
export default Sidebar;