import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import { MdOutlineFlag } from "react-icons/md";
import './Components.css'

const Review = () => {
    return ( 
        <div>
        <div>
          <div className="r-icon">
            <div className="oicon">
              <FiThumbsUp className="f-icon" />
              <div className="hoverfIcon"><p>Like the response :)</p></div>
            </div>
            <div className="oicon">
              <FiThumbsDown className="s-icon" />
              <div className="hoversIcon"><p>Dislike the response :(</p></div>
            </div>
            <div className="oicon">
              <MdOutlineFlag  className="t-icon"/>
              <div className="hovertIcon"><p>Report</p></div>
            </div>
          </div>

        </div>
      </div>
     );
}
 
export default Review;