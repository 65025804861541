import './Form.css'
import Brand from '../Brand.png'
import axios from 'axios';
import * as bootstrap from 'bootstrap';
import { auth } from '../firebase'; 
import image from '../PngItem_46390.png'
import { FaBullseye, FaGripfire } from "react-icons/fa";
import { IoPersonAddSharp } from "react-icons/io5";
import Papa from 'papaparse'
import { useState, useEffect, useMemo } from 'react';
import { AiOutlineCodeSandbox } from "react-icons/ai";
import { FaFilePdf } from "react-icons/fa6";


const Form = () => {

  const [userEmail, setUserEmail] = useState('');
  const [jdata, setJdata] = useState(null)
  const [file, setFile] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [user, setUser] = useState(null);
  const [uid, setUid] = useState("");
  const [username, setUsername] = useState("");
  const [usermail, setUsermail] = useState("");
  const [userId, setUserId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
    const [userAnswers, setUserAnswers] = useState([]);
    const [users, setUsers] = useState([]);
    const [systemPrompt, setSystemPrompt] = useState('');
    
  const [sysPersonaValue, setSysPersonaValue] = useState('');
  const [sysContextValue, setSysContextValue] = useState('');
  const [promptValue, setPromptValue] = useState('');
  const [selectedOption, setSelectedOption] = useState('Select Company');
  const [existingPrompt, setExistingPrompt] = useState(null);
  const [botresponse, setBotresponse] = useState('Test your prompt to see bot response');
  const [isLoading, setIsLoading] = useState(false);
  const [superadmin, setSuperadmin] = useState(false);
  const [validadmin, setValidadmin] = useState(false);
  const [adminemail, setAdminEmail] = useState('');
  const [admins, setAdmins] = useState([]);

  const [agentid, setAgentid] = useState("");
  const [compname, setCompname] = useState("Select Option");
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [apicall, setApicall] = useState("openai");


    const handleOptionSelect = (option) => {
      setSelectedOption(option);
    };

    const uploadFunction = (name, id) => {
      setSelectedOption(name);
      setCompanyId(id)
    }
    const handleFileChange = (event) => {
      // Get the selected file from input
      const file = event.target.files[0];
      setSelectedFile(file);
    };

    const toastTrigger = document.getElementById('liveToastBtn')
    const toastLiveExample = document.getElementById('liveToast')
    
    if (toastTrigger) {
      const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
      toastTrigger.addEventListener('click', () => {
        toastBootstrap.show()
      })
    }

    const toastTrigger2 = document.getElementById('liveToastBtn2')
    const toastLiveExample2 = document.getElementById('liveToast2')
    
    if (toastTrigger2) {
      const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample2)
      toastTrigger2.addEventListener('click', () => {
        toastBootstrap.show()
      })
    }

    const toastTrigger3 = document.getElementById('liveToastBtn3')
    const toastLiveExample3 = document.getElementById('liveToast3')
    
    if (toastTrigger3) {
      const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample3)
      toastTrigger3.addEventListener('click', () => {
        toastBootstrap.show()
      })
    }  


    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((currentUser) => {
        if (currentUser) {
          setUser(currentUser);
          setUid(currentUser.uid);
          setUserId(currentUser.uid);
          setUsername(currentUser.displayName) 
          setUsermail(currentUser.email)
          if(currentUser.email=='vishnu.dn@dataknobs.com' || currentUser.email=='prashantk@dataknobs.com'){
            setSuperadmin(true);
          }
        }
      });



  
      return () => unsubscribe();
    }, []);



    const fetchAdmins = async () => {
      try {
        const response = await axios.get('https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/admins');
        setAdmins(response.data); // Assuming the admins array is directly available in response.data
      } catch (error) {
        console.error('Error:', error);
      }
    };
    
  
    useEffect(() => {
      // Fetch admins when the component mounts
      fetchAdmins();
    }, []);
  

    useEffect(() => {
      const fetchAdmins = async () => {
        try {
          const response = await axios.get('https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/admins');
          const admins = response.data;
  
          // Check if the logged-in user's email matches any adminEmail
          const isAdmin = admins.some(admin => admin.adminEmail === usermail);
  
          setValidadmin(isAdmin);
        } catch (error) {
          console.error('Error fetching admins:', error);
        }
      };
  
      if (usermail) {
        fetchAdmins();
      }
    }, [usermail]);

    const [formData, setFormData] = useState({
      companyId: '',
      companyName: '',
      companyAdminName: '',
      address: '',
      city: '',
      state: '',
      country:'',
   
    });

    const handleChangeCompany = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmitCompany = async (e) => {
   
      e.preventDefault();
      const data = {
        ...formData,
        companyAdminId: uid,
      };
  
      try {
        await axios.post('https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/companies', data);
        // Handle success (redirect, show a success message, etc.)
        console.log('Company created successfully!');
      } catch (error) {
        // Handle error (show an error message, log the error, etc.)
        console.error('Error creating company:', error);
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
         

          if(usermail=='vishnu.dn@dataknobs.com' || usermail=='prashantk@dataknobs.com'){
            const response = await axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/getAllCompanies`);

            setCompanies(response.data.companies)
          }
          else{
      const response = await fetch(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/companies-by-admin/${uid}`);
            if (!response.ok) {
              throw new Error('Network response was not ok.');
            }
          const data = await response.json();
          setCompanies(data);
          }

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }

        fetchData();
    }, [uid]);
  


    useEffect(() => {
 

      const fetchQuestions = async () => {
        try {
          const response = await axios.get('https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/questions/ABC123');
          // Extract the questions from the API response
          if (response && response.data && response.data.data) {
            setQuestions(response.data.data);
          }
        } catch (error) {
          console.error('Error fetching questions:', error);
        }
      };

      const fetchCompanies = async () => {
        try {

          if(usermail=='prashantk@dataknobs.com' || usermail=='vishnu.dn@dataknobs.com'){
            const response = await axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/getAllCompanies`);
            // Extract the questions from the API response
            if (response && response.data && response.data.data) {
              setCompanies(response.data.companies);
            }
            console.log('hello')
            console.log(response.data.companies)
          }else{
            const response = await axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/companies-by-admin/${uid}`);
            // Extract the questions from the API response
            if (response && response.data && response.data.data) {
              setCompanies(response.data);
            }
            console.log('hello')
            console.log(response.data)
          }

      
        } catch (error) {
          console.error('Error fetching questions:', error);
        }
      };


  
      fetchQuestions();
      fetchCompanies();
    }, [uid]); 
 
    const fetchData = async (name, id) => {
      setSelectedOption(name);
      setCompanyId(id)
      try {
        const response = await fetch(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/getUsersByCompanyId/${id}`);
        if (!response.ok) {
          setUsers(['no users'])
        }
        const data = await response.json();
        if (data && data.users) {
       
          setUsers(data.users);
          console.log(data.users)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };



    const handleAnswerChange = (question, event) => {
      const updatedUserAnswers = [...userAnswers];
      const existingAnswerIndex = updatedUserAnswers.findIndex(
        (answer) => answer.question === question
      );
  
      if (existingAnswerIndex !== -1) {
        updatedUserAnswers[existingAnswerIndex].answer = event.target.value;
      } else {
        updatedUserAnswers.push({ question, answer: event.target.value });
      }
  
      setUserAnswers(updatedUserAnswers);
    };

    const handleSaveAnswers = async () => {

      try {
        const response = await fetch("https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/user-answers", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId, companyId, answers: userAnswers }),
        });
  
        if (response.ok) {
          console.log("User answers saved successfully!");
       
        } else {
          const errorResponse = await response.json();
          console.error(
            "Failed to save user answers. Server response:",
            errorResponse
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };


    const handleFileUpload = async (event) => {

const selectedFile = event.target.files[0];

if (selectedFile) {
  setFile(selectedFile);

  const reader = new FileReader();

  reader.onload = async (e) => {
    const csvData = e.target.result;

    // Split lines and extract questions
    const rows = csvData.split("\n");
    const extractedQuestions = rows
      .slice(1) // Skip the header row
      .map((row) => {
        const [, question] = row.split(",");
        return question ? question.trim() : null; // Ensure there is a question
      })
      .filter(Boolean); // Remove any null or empty strings

    setQuestions(extractedQuestions);
  };

  reader.readAsText(selectedFile);
}

handleCsvChange(event);
    };
    
  

  const handleCsvChange = (event) => {
    const selectedFile = event.target.files[0];
  
      if (selectedFile) {
        setFile(selectedFile);
  
        const reader = new FileReader();
  
        reader.onload = async (e) => {
          const csvData = e.target.result;

          const parsedData = Papa.parse(csvData, {
            header: true,
            skipEmptyLines: true,
          });
    
          const jsonData = parsedData.data.map(item => ({
            question: item.question,
            placeholder: item.placeholder,
          }));
    
          setJdata(jsonData); // Set as array of objects directly
          console.log('Array of objects:', jsonData); // Log the array of objects


          const jsonData1 = jsonData.reduce((obj, item) => {
            obj[item.question] = item.placeholder;
            return obj;
          }, {});

console.log('json data')
const test = 'How old are you?'
console.log(jsonData1);
       



        };
  
        reader.readAsText(selectedFile);
      }

  }
    
    
  
    

  const handleCompanyIdChange = (event) => {
    setCompanyId(event.target.value);
  };


  

const handleSubmit = async () => {
  
  if (file && questions.length > 0 && companyId) {
    try {
      // Check if the company ID exists in the Company table
      const companyCheckResponse = await fetch(
        `https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/companies/${companyId}`
      );

      if (companyCheckResponse.ok) {
        // Company exists, proceed to upload questions
        const response = await fetch("https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/questions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ companyId, questions }),
        });

        if (response.ok) {
          console.log("Questions uploaded successfully!");
          console.log("uploaded to " + companyId)
        } else {
          console.error("Failed to upload questions.");
        }
      } else {
        console.error("Company not found.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
  handleSubmitCsv();
    }

    const handleSubmitCsv = async () => {
      if(jdata){
        const response1 = await axios.post(
          'https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/upload-csv-data',
          { companyId: companyId, data: jdata },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
  
        if (response1.status === 200) {
          console.log('CSV Upload successful:', response1.data);
          // Handle success message or other actions upon successful upload
        } else {
          console.error('Error uploading CSV:', response1.data);
          // Handle error or display error message to the user
        }
      }
    }

    const handlePdfChange = (event) => {

      console.log(agentid + " " + compname)

      const file = event.target.files[0];
  
      if (file && file.type === 'application/pdf') {
        // You can perform additional checks or operations here
        // before setting the selected PDF file in the state.
  
        setSelectedPdf(file);
      } else {
        // Handle cases where the selected file is not a PDF.
        console.error('Please select a valid PDF file.');
      }
    };




  const handlePdfUpload = async () => {
    if (selectedPdf) {
      const formData = new FormData();
      formData.append('name', agentid); // Replace 'companyId' with the appropriate variable
      formData.append('file', selectedPdf);

      try {
        // Make a POST request to the specified API endpoint for file upload
        const response = await axios.post('https://gemini-search-chat-vtoo6mbt4q-uc.a.run.app/upload', formData);
        
        // Log the response in the console
        console.log('Response:', response.data); // Modify this as per your response structure

        // Handle the response data as needed
        // For example, update state, show a success message, etc.
      } catch (error) {
        console.error('Error uploading file:', error);
        // Handle errors appropriately (show error message, etc.)
      }
    } else {
      console.error('No file selected');
      // Handle case where no file is selected
    }
  }
  
  const handleUserEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handleUserSubmit = async () => {
    try {
      const response = await axios.post('https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/company-users', {
        companyId: companyId,
        users: [userEmail]
      });

      console.log(response.data.message); // Response message
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAdminEmailChange = (e) => {
    setAdminEmail(e.target.value);
  };

  const handleAdminSubmit = async () => {
    try {
      const response = await axios.post('https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/admins', {
        adminEmail: adminemail,
      });

      console.log(response.data.message); // Response message
      // You might want to fetch and update the admins list after successfully adding an admin.
      fetchAdmins();
    } catch (error) {
      console.error('Error:', error);
    }
  };



  const handleGeneratePrompt = () => {
    // Concatenate system persona and system context
    const generatedSystemPrompt = sysPersonaValue + '. ' + sysContextValue + '. ' + promptValue + '.';

    // Set the concatenated system prompt in state
    setSystemPrompt(generatedSystemPrompt);
  };


  const handlePersonaChange = (event) => {
    setSysPersonaValue(event.target.value);
  };

  const handleContextChange = (event) => {
    setSysContextValue(event.target.value);
  };

    const handlePromptChange = (event) => {
    setPromptValue(event.target.value);
  };

  const handlePromptSubmit = async () => {
    const url = 'https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/prompt';
  
    const promptData = {
      companyId: companyId,
      companyName: selectedOption,
      companyAdminId: uid,
      systemPrompt: systemPrompt
    };
  
    try {
      const response = await axios.post(url, promptData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (response.status === 200) {
        console.log('Prompt uploaded successfully!');
        // Perform any actions upon successful prompt upload
      }
    } catch (error) {
      console.error('Error uploading prompt:', error);
      // Handle error scenario
    }
    const toastTrigger4 = document.getElementById('liveToastBtn4')
    const toastLiveExample4 = document.getElementById('liveToast4')
    const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample4)
    toastBootstrap.show()
  };



  useEffect(() => {
  axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/prompt/${companyId}`)
    .then(response => {
      if (response.status === 200) {
        setExistingPrompt(response.data.prompt.systemPrompt);
      } else {
        console.error('Failed to fetch system prompt:', response.statusText);
      }
    })
    .catch(error => {
      setExistingPrompt(null)
      console.error('Error fetching system prompt:', error.message);
    });
}, [companyId]);
  
const handleExistingPrompt = async () => {
  const editedContent = document.getElementById('existing').textContent;
  const url = 'https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/prompt';
  
    const promptData = {
      companyId: companyId,
      companyName: selectedOption,
      companyAdminId: uid,
      systemPrompt: editedContent,
    };
  
    try {
      const response = await axios.post(url, promptData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (response.status === 200) {
        console.log('Prompt uploaded successfully!');
        // Perform any actions upon successful prompt upload
      }
    } catch (error) {
      console.error('Error uploading prompt:', error);
      // Handle error scenario
    }
    const toastTrigger4 = document.getElementById('liveToastBtn4')
    const toastLiveExample4 = document.getElementById('liveToast4')
    const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample4)
    toastBootstrap.show()
}


  const testPrompt = async () => {

    const editedContent = document.getElementById('testprompt').textContent;
  setIsLoading(true);


if(apicall=='openai'){
  const formData = new FormData();
  formData.append("name", usermail);
  formData.append("filename", companyId);
  formData.append("id", uid);
  formData.append("prompt", editedContent);
  axios.post("https://openai-gemini-chatbot-api-5wz7dep6ya-uc.a.run.app/openai", formData, {
  headers: { 'Content-Type': 'multipart/form-data' },
})
.then((response) => {
  setIsLoading(false);
  setBotresponse(response.data.answer)
})
.catch((error) => {
  console.error('Error', error);
});
} else {
const apiUrl = 'https://gemini-search-chat-vtoo6mbt4q-uc.a.run.app/gemini';
const agentId = '35e1bee0-071d-4e1e-95cf-0c77829d8a6b';

try {
  const response = await axios.get(apiUrl, {
    params: {
      prompt: editedContent,
      agent_id: agentId,
    },
  });
  setIsLoading(false)
  setBotresponse(response.data.answer[0])
  console.log('Gemini API Response:', response.data.answer[0]);
} catch (error) {
  console.error('Error fetching Gemini API:', error);
}
}


  }






    return (
        <div className='form'>
    <div style={{paddingLeft:"3vw", paddingTop:"3vw",width:"100%"}}>
    
  
  
   {user ? ( 
   
   
   
   <> 

   <div style={{display:"flex"}} className='brandname'>
    <div style={{width:"6vw", marginTop:"-5%"}}>
   <img src={Brand} style={{borderRadius:"10px", width:"100%"}}/>
   </div>&nbsp;
   <h3 style={{fontWeight:"20px"}} >Company Onboarding</h3>
   
   </div>
   
   <div class="card text-bg-white mb-3" style={{maxWidth: "max-content", boxShadow:"0 0 1px rgba(0, 0, 0, 0.528)", marginBottom:"5vh"}} >

  
  
  <div class="card-body">
    <p class="card-text"><div style={{display:"flex"}}><h5 style={{color:"#14183e"}}>{username} &nbsp;&nbsp;</h5> <p>|&nbsp;&nbsp; {usermail}</p></div> <div style={{display:"flex", fontSize:"0.8rem", color:"grey"}} ><p>User ID&nbsp;&nbsp;:&nbsp;&nbsp;</p> <p>{uid}</p></div></p>
  </div>
</div>



{validadmin ? (
<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Create Company</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Upload questions through CSV</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-pdf" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Upload knowledgebase</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-user-tab" data-bs-toggle="pill" data-bs-target="#pills-user" type="button" role="tab" aria-controls="pills-user" aria-selected="false">Add User</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-prompt-tab" data-bs-toggle="pill" data-bs-target="#pills-prompt" type="button" role="tab" aria-controls="pills-prompt" aria-selected="false">Generate Prompt</button>
  </li>

  {superadmin ? (  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-prompt-tab" data-bs-toggle="pill" data-bs-target="#pills-admin" type="button" role="tab" aria-controls="pills-admin" aria-selected="false">Manage Admins</button>
  </li>
  ) : (<></>)}

</ul>) : (<></>)}

</>) : (<><br/><br/></>)}
  





{user ? ( 
  <>
  
  {validadmin ? (
  
  
  
  <div className='mainbox'  style={{width:'95%'}}>
   <div class="floral-design">
   <svg xmlns="http://www.w3.org/2000/svg" width="135" height="351" viewBox="0 0 135 451">
  <g fill="none" opacity=".2" transform="translate(-49 -210)">
    <path fill="#00D09C" d="M172.508273,377.778401 C228.452677,377.786729 269.314026,334.445769 269.306067,280.974481 C269.298108,227.503194 221.851111,177.537386 172.479452,184.14174 C123.107793,190.746094 68.2208897,222.488472 75.681658,280.94566 C83.1424263,339.402849 116.563869,377.770074 172.508273,377.778401 Z" transform="rotate(39 172.151 280.665)"/>
    <path fill="#00D09C" d="M71.8653897,231.377441 C83.5181086,231.379176 92.0291613,222.34733 92.0275028,211.204421 C92.0258443,200.061511 82.1430585,189.649111 71.8593837,191.025397 C61.5757088,192.401682 50.1432584,199.016494 51.6972705,211.198418 C53.2512827,223.380341 60.2126708,231.375707 71.8653897,231.377441 Z" transform="rotate(39 71.791 211.14)"/>
    <path fill="#80868B" d="M240.384216,573.666291 C285.216381,576.402089 317.69194,541.53558 321.547246,492.728131 C325.402553,443.920682 285.19087,405.004466 240.360119,411.765805 C195.529368,418.527144 162.540736,446.291386 159.197089,492.703965 C155.853441,539.116544 195.552052,570.930494 240.384216,573.666291 Z" transform="rotate(39 240.402 492.408)"/>
    <path fill="#00D09C" d="M101.220037,405.240137 C106.792556,405.580186 110.829183,401.24642 111.308387,395.179851 C111.787591,389.113282 106.789385,384.276153 101.217041,385.11656 C95.6446975,385.956967 91.5442977,389.407951 91.1286911,395.176847 C90.7130844,400.945743 95.647517,404.900089 101.220037,405.240137 Z" transform="rotate(39 101.222 395.14)"/>
    <path fill="#5367FF" d="M161.033141,805.920609 C223.850635,809.753884 269.354422,760.900517 274.756363,692.51374 C280.158305,624.126963 223.814891,569.59933 160.999377,579.073011 C98.1838635,588.546692 51.9611747,627.448685 47.2761547,692.47988 C42.5911348,757.511074 98.2156469,802.087334 161.033141,805.920609 Z" transform="rotate(39 161.058 692.066)"/>
  </g>
</svg>
   </div>
  <>
  <div class="tab-content" id="pills-tabContent">



    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0"><ul className='questions'>
          <h4>Create Company</h4><br/><br/><br/>
          <form  className='questions'>
        <div className='glass'>
          <li htmlFor="companyId">Company ID:</li>
          <input className='answers'
            type="text"
            id="companyId"
            name="companyId"
            value={formData.companyId}
            onChange={handleChangeCompany}
          />
        </div>
        <div className='glass'>
          <li htmlFor="companyName">Company Name:</li>
          <input className='answers'
            type="text"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleChangeCompany}
          />
        </div>
        <div className='glass'>
          <li htmlFor="companyAdminName">Company Admin Name:</li>
          <input className='answers'
            type="text"
            id="companyAdminName"
            name="companyAdminName"
            value={formData.companyAdminName}
            onChange={handleChangeCompany}
          />
        </div>
        <div className='glass'>
          <li htmlFor="address">Address:</li>
          <input className='answers'
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChangeCompany}
          />
        </div>
        <div className='glass'>
          <li htmlFor="city">City:</li>
          <input className='answers'
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChangeCompany}
          />
        </div>
        <div className='glass'>
          <li htmlFor="state">State:</li>
          <input className='answers'
            type="text"
            id="state"
            name="state"
            value={formData.state}
            onChange={handleChangeCompany}
          />
        </div>
        <div className='glass'>
          <li htmlFor="country">Country:</li>
          <input className='answers'
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChangeCompany}
          />
        </div>
        
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{width:"max-content", marginLeft:"80%", marginTop:"5vh"}}>
  Create Company
</button>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-centered" style={{width:"20vw"}}>
    <div class="modal-content" style={{textAlign:"center"}}>
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <h6>Are you sure?</h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={handleSubmitCompany} id="liveToastBtn">Submit</button>
      </div>
    </div>
  </div>
</div>
      </form>
      </ul>
      </div>
      
      <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
      <ul className='questions'>
      <h4>Upload csv</h4><br/><br/><br/>
     
     
      <div className="dropdown">
  <a className="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    {selectedOption}
  </a>

  <ul className='dropdown-menu'>
        {companies.map((company, index) => (
          <li key={index} className='dropdown-item' onClick={()=>uploadFunction(company.companyName, company.companyId)}>
            {company.companyName}
          </li>
        ))}
      </ul>
</div>

      <li className='glass'>
        upload questions<br/>
        <input
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
        style={{fontSize:"small", border:"none", backgroundColor:"black", color:"white", borderRadius:"5px"}}
        
      />
      </li>
      </ul>
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal2" style={{width:"max-content", marginLeft:"80%", marginTop:"5vh"}}>
  Upload
</button>

<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-centered" style={{width:"20vw"}}>
    <div class="modal-content" style={{textAlign:"center"}}>
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <h6>Are you sure?</h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={handleSubmit} id="liveToastBtn2">Submit</button>
      </div>
    </div>
  </div>
</div>
      </div>


      <div class="tab-pane fade" id="pills-pdf" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
      <ul className='questions'>
      <h4>Upload knowledgebase for data store</h4><br/><br/><br/>
     
     
      <div className="dropdown">
  <a className="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    {compname}
  </a>

  <ul className='dropdown-menu'>
        {/* {companies.map((company, index) => (
          <li key={index} className='dropdown-item' onClick={()=>uploadFunction(company.companyName, company.companyId)}>
            {company.companyName}
          </li>
        ))} */}
      <li className='dropdown-item' onClick={()=>{setCompname("NutriBlend"); setAgentid("dietitian")}}>NutriBlend</li>
      <li className='dropdown-item' onClick={()=>{setCompname("CA-finance"); setAgentid("ca")}}>CA-finance</li>
      <li className='dropdown-item' onClick={()=>{setCompname("Interior Designer"); setAgentid("interior-designer")}}>Interior Designer</li>
      <li className='dropdown-item' onClick={()=>{setCompname("Housefinder"); setAgentid("housefinder")}}>Housefinder</li>
      <li className='dropdown-item' onClick={()=>{setCompname("portfolio-planner"); setAgentid("portfolio-planner")}}>portfolio-planner</li>
      </ul>
</div>


      </ul>
      


<div class="card text mb-3" style={{width: "18rem", paddingLeft:'2rem',paddingRight:'2rem', paddingTop:'3rem', paddingBottom:'3rem', marginTop:'5%'}}>
  <h2 style={{textAlign:'left'}}><FaFilePdf/></h2>
  <br/>
  {/* <div class="card-body"> */}
    <h5 class="card-title" style={{textAlign:'left'}}>Upload PDF</h5>
    <p class="card-text" style={{textAlign:'left'}}>Upload PDF into the GCP Bucket to generate data store</p>
    <br/>
    <input
        type="file"
        accept=".pdf"
        onChange={handlePdfChange}
        style={{fontSize:"small", border:"none", backgroundColor:"black", color:"white", borderRadius:"5px"}}
        
      />
  {/* </div> */}
  <br/>
  <br/>
  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal3" style={{width:"max-content"}}>
  Upload
</button>
</div>
  

<div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-centered" style={{width:"20vw"}}>
    <div class="modal-content" style={{textAlign:"center"}}>
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <h6>Are you sure?</h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" id="liveToastBtn3" onClick={handlePdfUpload}>Submit</button>
      </div>
    </div>
  </div>
</div>
      </div>


      <div class="tab-pane fade" id="pills-user" role="tabpanel" aria-labelledby="pills-user-tab" tabindex="0" >
  {/*   table body     */}
  <>

<div style={{display:'flex', width:'90%', justifyContent:'space-between'}}>

<div className="dropdown">
  <a className="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    {selectedOption}
  </a>

  <ul className='dropdown-menu'>
        {companies.map((company, index) => (
          <li key={index} className='dropdown-item' onClick={()=>fetchData(company.companyName, company.companyId)}>
            {company.companyName}
          </li>
        ))}
      </ul>
</div>

<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{backgroundColor:'rgb(0, 208, 156)', border:'none', marginLeft:'30%'}}>
<IoPersonAddSharp /> Add user
</button>
</div>
<br/><br/><br/>
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Add User To {selectedOption}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div class="input-group mb-3">

  <input type="text" class="form-control" aria-label="Text input with checkbox" placeholder='Enter user email'             value={userEmail}
            onChange={handleUserEmailChange}/>
</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"  id="liveToastBtn4" onClick={handleUserSubmit}>Submit</button>
        {/* <button id="liveToastBtn" type="button" class="btn btn-primary" onClick={handleUserSubmit} data-bs-dismiss="modal" >Submit</button> */}
      </div>
    </div>
  </div>
</div>



<table id="dtBasicExample" class="table table-striped table-bordered table-sm" cellspacing="0" style={{width:'0%'}}>
  <thead>
    <tr>
      <th class="th-sm">Available Users

      </th>
    </tr>
  </thead>
  <tbody>
  {users.map((user, index) => (
          <tr key={index}>
            <td>{user}</td>
          </tr>
        ))}
  
  </tbody>

</table>

</>
      </div>






      <div class="tab-pane fade" id="pills-prompt" role="tabpanel" aria-labelledby="pills-prompt-tab" tabindex="0"><ul className='questions'>
          <h4>Prompt</h4><br/><br/><br/>
          <form  className='questions'>

          <div className='glass'>
          <div className="dropdown">
  <a className="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    {selectedOption}
  </a>

  <ul className='dropdown-menu'>
        {companies.map((company, index) => (
          <li key={index} className='dropdown-item' onClick={()=>fetchData(company.companyName, company.companyId)}>
            {company.companyName}
          </li>
        ))}
      </ul>
</div>
</div>
<br/>

      <div class="p-3 bg-light bg-opacity-10 border border-info border-start-0 rounded-end" style={{width:'150%'}}>
        <div className='glass'>
          <li htmlFor="companyId">System Persona</li>
          <input className='answers'
            type="text"
            id="sysPersona"
            style={{width:'35vw'}}
            onChange={handlePersonaChange}
          />
        </div>
        <br/><br/>
        <div className='glass'>
          <li htmlFor="companyName">System Context</li>
          <input className='answers'
            type="text"
            id="sysContext"
            style={{width:'35vw'}}
            onChange={handleContextChange}
          />
        </div>
          <br/>
          <div className='glass'>
          <li htmlFor="companyName">Prompt Template</li>
          <input className='answers'
            type="text"
            id="sysContext"
            style={{width:'35vw'}}
            onChange={handlePromptChange}
          />
        </div>
        </div>



       <br/>
        
        <button onClick={handleGeneratePrompt} type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#promptmodal" style={{width:"max-content", marginLeft:"90%", marginTop:"5vh", marginBottom:'8%'}}>
  Generate
</button>
<br/>
<br/>
<br/>

{existingPrompt ? (<div>
  
  
  <div style={{justifyContent:'right', alignContent:'right', alignItems:'right', width:'150%'}}><br/><h4>Edit Existing Prompt</h4><br/><br/><p class="p-3 bg-light bg-opacity-10 border border-info border-start-0 rounded-end" style={{ padding:'10px', borderRadius:'10px'}} contentEditable="true" id="existing">{existingPrompt}</p><br/><button type="button" class="btn btn-primary" onClick={handleExistingPrompt} style={{width:'max-content', color:'white', alignSelf:'right', justifyContent:'right', marginLeft:'80%'}}>Submit</button></div>

  <br/><br/>

  <div style={{justifyContent:'right', alignContent:'right', alignItems:'right', width:'150%'}}><br/><h4>Test your prompt</h4><br/><br/><p class="p-3 bg-light bg-opacity-10 border border-info border-start-0 rounded-end" style={{ padding:'10px', borderRadius:'10px'}} contentEditable="true"  id="testprompt">{existingPrompt}</p><br/><button type="button" class="btn btn-primary" onClick={testPrompt} style={{width:'max-content', color:'white', alignSelf:'right', justifyContent:'right', marginLeft:'80%'}}>Test</button></div>
  
  
  <div style={{justifyContent:'right', alignContent:'right', alignItems:'right', width:'150%'}}><br/><div style={{display:'flex'}}><h4>Bot Response</h4>  &nbsp;&nbsp;&nbsp;
  
  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
  <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked={apicall === "openai"} onChange={() => setApicall("openai")} />
  <label class="btn btn-outline-warning" for="btnradio1">OpenAI</label>
  <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" checked={apicall === "gemini"} onChange={() => setApicall("gemini")} />
  <label class="btn btn-outline-warning" for="btnradio3">Gemini</label>
</div>

  
  
  </div><br/><br/><p class="p-3 bg-dark bg-opacity-10 border border-info border-start-0 rounded-end" style={{ padding:'10px', color:'white', borderRadius:'10px'}} contentEditable="true">{botresponse}</p><br/></div>


  </div>) : (<></>)}




<div class="modal fade" id="promptmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{padding:'3vh'}}>
  <div class="modal-dialog modal-dialog-centered" style={{width:"25vw"}}>
    <div class="modal-content" style={{textAlign:"center"}}>
      <div class="modal-header" style={{justifyContent:'center', border:'none'}}>
        <h2><FaGripfire/>&nbsp;System Prompt</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" >
        <p id='modalBody'>{systemPrompt}</p>
        
      </div>
      <div class="modal-footer" style={{justifyContent:'center' }}>
        
      <h5>Are you sure?</h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" id="liveToastBtn4" onClick={handlePromptSubmit} >Submit</button>
      </div>
    </div>
  </div>
</div>
      </form>
      </ul>
      </div>


      <div className="tab-pane fade" id="pills-admin" role="tabpanel" aria-labelledby="pills-admin-tab" tabIndex="0">
      {/*   table body     */}
      <>
        <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between' }}>
          {/* Remove the company dropdown as it's not needed */}
          <button
            type="button"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#adminModal"
            style={{ backgroundColor: 'rgb(0, 208, 156)', border: 'none', marginLeft: '30%' }}
          >
            <IoPersonAddSharp /> Add Admin
          </button>
        </div>
        <br />
        <br />
        <br />
        <div className="modal fade" id="adminModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="adminModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="adminModalLabel">
                  Add Admin
                </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Text input with checkbox"
                    placeholder="Enter admin email"
                    value={adminemail}
                    onChange={handleAdminEmailChange}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" id="liveToastBtn4" onClick={handleAdminSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        <table id="dtBasicExample" className="table table-striped table-bordered table-sm" cellSpacing="0" style={{ width: '0%' }}>
          <thead>
            <tr>
              <th className="th-sm">Available Admins</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin, index) => (
              <tr key={index}>
                <td>{admin.adminEmail}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    </div>






      </div></>

        </div>

  )

          : 
          
          
          
          (    <h4 style={{color:'darkgrey', marginLeft:'30%', marginTop:'10%'}}><AiOutlineCodeSandbox/>&nbsp; You don't have access :(</h4>   ) }


</>

) : (
  <div style={{display:"flex", marginTop:"15%"}} className='brandname'>
    <div style={{width:"8vw", marginTop:"-5%"}}>
   <img src={Brand} style={{borderRadius:"10px", width:"100%"}}/>
   </div>&nbsp;
   <strong style={{fontSize:"6vh"}}>Admin's Corner</strong>
   
   </div>

)}

    </div>  


<div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
  
      <strong class="me-auto" style={{color:"green"}}>Success!</strong>
      <small>Just now</small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      Your details have been saved!
    </div>
  </div>
</div>

<div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div id="liveToast2" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
  
      <strong class="me-auto" style={{color:"green"}}>Success!</strong>
      <small>Just now</small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      Your file has been uploaded!
    </div>
  </div>
</div>
<div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div id="liveToast3" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
  
      <strong class="me-auto" style={{color:"green"}}>Success!</strong>
      <small>Just now</small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      Your file has been uploaded!
    </div>
  </div>
</div>

<div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div id="liveToast4" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
  
      <strong class="me-auto" style={{color:"green"}}>Success!</strong>
      <small>Just now</small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      Your prompt has been saved!
    </div>
  </div>
</div>

                <br/><br/>





                {isLoading && (
  <div class="shapes"></div>
)}
    </div>
    
    );
}
 
export default Form;